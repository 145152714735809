import React from "react";
import { Row, Col } from "react-grid-system";
import CountUp from "react-countup";
import styled from "styled-components";

const Card = styled.div`
  text-align: center;
  font-size: 16px;
  background: #438cee;
  color: #fff;
  padding: 48px;

  span {
    display: block;
    font-weight: bold;
    font-size: 64px;
  }

  .inline span {
    display: inline;
  }
`;

export default function MetricsRow({ rowData }) {
  return (
    <div>
      <Row>
        {rowData.map((datum, index) => (
          <Col>
            <Card>
              <span className="inline">
                <CountUp end={datum[0]} duration={1} />
                {datum[1].includes("Rate") ? "%" : null}
              </span>
              {datum[1]}
            </Card>
          </Col>
        ))}
      </Row>
      <br />
    </div>
  );
}
