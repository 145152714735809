import React, { useEffect, useState } from "react";

import Label from "components/Elements/Label";

import {
  getOnboardingData,
  getOnboardingRawData,
  fetchUserDataByUid
} from "api/db";

import Spinner from "react-spinkit";
import { DashboardContainer, SpinnerContainer } from "components/Admin/styles";

import MetricsRow from "components/Admin/MetricsRow";
import { SmallPill } from "components/Post/styles";

export default function Onboarding() {
  // onboarding
  const [experienceData, setExperienceData] = useState(null);
  const [interestData, setInterestData] = useState(null);
  const [motivationData, setMotivationData] = useState(null);
  const [numOnboarded, setNumOnboarded] = useState(null);
  const [userData, setUserData] = useState([]);
  const [userDataUids, setUserDataUids] = useState([]);

  useEffect(() => {
    getOnboardingData().then(snapshot => {
      let data = snapshot.val();
      setNumOnboarded(data.count);
      setExperienceData(data.experience);
      setInterestData(data.interest);
      setMotivationData(data.motivation);
    });

    getOnboardingRawData().then(snapshot => {
      let data = Object.values(snapshot.val());
      let uids = Object.keys(snapshot.val());
      setUserDataUids(uids);
      data.map((submission, index) => {
        fetchUserDataByUid(uids[index]).once("value", function(snapshot) {
          let data = snapshot.val();
          const object = { ...data, ...submission };
          setUserData(users => users.concat(object));
        });
      });
    });
  }, []);

  return (
    <DashboardContainer>
      <Label>
        {" "}
        <a href="/admin/dashboard"> &larr; Back</a>{" "}
      </Label>
      <h1>Onboarding Metrics</h1>
      {experienceData ? (
        <>
          <MetricsRow rowData={[[numOnboarded, "Number Onboarded"]]} />
          <MetricsRow
            rowData={[
              [
                interestData ? interestData["web-development"] : 0,
                "Web Dev Interest"
              ],
              [
                interestData ? interestData["machine-learning"] : 0,
                "ML Interest"
              ]
            ]}
          />
          <MetricsRow
            rowData={[
              [motivationData ? motivationData["career"] : 0, "Job-Seekers"],
              [motivationData ? motivationData["exploration"] : 0, "Explorers"],
              [motivationData ? motivationData["other"] : 0, "Other"]
            ]}
          />
          <MetricsRow
            rowData={[
              [experienceData ? experienceData["beginner"] : 0, "Beginner"],
              [
                experienceData ? experienceData["semi-beginner"] : 0,
                "Semi-beginner"
              ],
              [
                experienceData ? experienceData["intermediate"] : 0,
                "Intermediate"
              ],
              [experienceData ? experienceData["advanced"] : 0, "Advanced"]
            ]}
          />

          {userData.length > 50 && (
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>image</th>
                  <th>uid</th>
                  <th>username</th>
                  <th>name</th>
                  <th>interest</th>
                  <th>motivation</th>
                  <th>experience</th>
                </tr>
              </thead>
              <tbody>
                {userData
                  .sort(function(a, b) {
                    return new Date(b.created_at) - new Date(a.created_at);
                  })
                  .map((user, index) => {
                    return (
                      <tr
                        key={user && userDataUids[index] && userDataUids[index]}
                      >
                        <td>{index}</td>
                        <td>
                          <img
                            src={user && user.photoURL && user.photoURL}
                            height="40px"
                          ></img>
                        </td>
                        <td>{userDataUids[index]}</td>
                        <td>
                          {" "}
                          <a href={`/user/${user.id}`}>
                            @{user && user.id && user.id.slice(0, 10)}
                          </a>
                        </td>
                        <td>{user && user.username && user.username}</td>
                        <td>
                          {" "}
                          <SmallPill c="#D1F0FD">
                            {user && user.interest && user.interest}
                          </SmallPill>
                        </td>
                        <td>
                          <SmallPill c="#D1F0FD">
                            {user && user.experience && user.experience.slice(0, 8) + "..."}
                          </SmallPill>
                        </td>
                        <td>
                          <SmallPill c="#D1F0FD">
                            {user && user.motivation && user.motivation.slice(0, 8) + "..."}
                          </SmallPill>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </>
      ) : (
        <SpinnerContainer>
          <Spinner name="ball-scale-multiple" color="#438cee" />
        </SpinnerContainer>
      )}
    </DashboardContainer>
  );
}
